<template>
    <div>
    <div id="outerContainer">
      <div id="mainContainer">
        <div class="findbar hidden doorHanger" id="findbar">
          <div id="findbarInputContainer">
            <input id="findInput" class="toolbarField" title="Find" placeholder="Find in document…" tabindex="91" data-l10n-id="find_input" aria-invalid="false" @keydown="searchKeyDown">
            <div class="splitToolbarButton">
              <button id="findPrevious" class="toolbarButton" title="Find the previous occurrence of the phrase" tabindex="92" data-l10n-id="find_previous" @click="findPrevious">
                <span data-l10n-id="find_previous_label">Previous</span>
              </button>
              <div class="splitToolbarButtonSeparator"></div>
              <button id="findNext" class="toolbarButton" title="Find the next occurrence of the phrase" tabindex="93" data-l10n-id="find_next" @click="findNext">
                <span data-l10n-id="find_next_label">Next</span>
              </button>
            </div>
          </div>
          <div id="findbarOptionsOneContainer">
            <input type="checkbox" id="findHighlightAll" class="toolbarField" tabindex="94" @click="highlightAllHandler()">
            <label for="findHighlightAll" class="toolbarLabel" data-l10n-id="find_highlight">Highlight All</label>
            <input type="checkbox" id="findMatchCase" class="toolbarField" tabindex="95" @click="caseSensitiveHandler()">
            <label for="findMatchCase" class="toolbarLabel" data-l10n-id="find_match_case_label">Match Case</label>
          </div>
          <div id="findbarOptionsTwoContainer">
            <input type="checkbox" id="findMatchDiacritics" class="toolbarField" tabindex="96" @click="matchDiacriticsHandler()">
            <label for="findMatchDiacritics" class="toolbarLabel" data-l10n-id="find_match_diacritics_label">Match Diacritics</label>
            <input type="checkbox" id="findEntireWord" class="toolbarField" tabindex="97" @click="entireWordHandler()">
            <label for="findEntireWord" class="toolbarLabel" data-l10n-id="find_entire_word_label">Whole Words</label>
          </div>
          <div id="findbarMessageContainer" aria-live="polite">
            <span id="findResultsCount" class="toolbarLabel"></span>
            <span id="findMsg" class="toolbarLabel"></span>
          </div>
        </div>  <!-- findbar -->

        <div id="secondaryToolbar" class="secondaryToolbar hidden doorHangerRight">
          <div id="secondaryToolbarButtonContainer">
            <button id="secondaryPrint" class="secondaryToolbarButton visibleMediumView" title="Print" tabindex="53" data-l10n-id="print">
              <span data-l10n-id="print_label">Print</span>
            </button>

            <button id="secondaryDownload" class="secondaryToolbarButton visibleMediumView" title="Download" tabindex="54" data-l10n-id="download">
              <span data-l10n-id="download_label">Download</span>
            </button>
          </div>
        </div>  <!-- secondaryToolbar -->

        <div class="toolbar">
          <div id="toolbarContainer">
            <div id="toolbarViewer">
              <div id="toolbarViewerLeft">
                <div class="toolbarButtonSpacer"></div>
                <button id="viewFind" class="toolbarButton" title="Find in Document" tabindex="12" data-l10n-id="findbar" aria-expanded="false" aria-controls="findbar" @click="toggle()">
                  <span data-l10n-id="findbar_label">Find</span>
                </button>
                <div class="splitToolbarButton hiddenSmallView">
                  <button class="toolbarButton" title="Previous Page" id="previous" tabindex="13" data-l10n-id="previous" @click="page > 1 ? page-- : 1">
                    <span data-l10n-id="previous_label">Previous</span>
                  </button>
                  <div class="splitToolbarButtonSeparator"></div>
                  <button class="toolbarButton" title="Next Page" id="next" tabindex="14" data-l10n-id="next" @click="page < numPages ? page++ : 1">
                    <span data-l10n-id="next_label">Next</span>
                  </button>
                </div>
                <input type="number" id="pageNumber" class="toolbarField" title="Page" size="4" min="1" tabindex="15" data-l10n-id="page" autocomplete="off" v-model="page">
                <span id="numPages" class="toolbarLabel">/ {{ numPages ? numPages : '∞' }}</span>
              </div>
              <div id="toolbarViewerRight">
                <button id="print" class="toolbarButton hiddenMediumView" title="Print" tabindex="33" data-l10n-id="print" @click="print">
                  <span data-l10n-id="print_label">Print</span>
                </button>

                <button id="download" class="toolbarButton hiddenMediumView" title="Download" tabindex="34" data-l10n-id="download" @click="download">
                  <span data-l10n-id="download_label">Download</span>
                </button>

                <div class="verticalToolbarSeparator hiddenSmallView"></div>

                <!-- Should be visible when the "editorModeButtons" are visible. -->
                <div id="editorModeSeparator" class="verticalToolbarSeparator hidden"></div>
              </div>
              <div id="toolbarViewerMiddle">
                <div class="splitToolbarButton">
                  <button id="zoomOut" class="toolbarButton" title="Zoom Out" tabindex="21" data-l10n-id="zoom_out" @click="scale -= scale > 0.3 ? 0.2 : 0">
                    <span data-l10n-id="zoom_out_label">Zoom Out</span>
                  </button>
                  <div class="splitToolbarButtonSeparator"></div>
                  <button id="zoomIn" class="toolbarButton" title="Zoom In" tabindex="22" data-l10n-id="zoom_in" @click="scale += scale < 5 ? 0.2 : 0">
                    <span data-l10n-id="zoom_in_label">Zoom In</span>
                   </button>
                </div>
                <span id="scaleSelectContainer" class="dropdownToolbarButton">
                  <select id="scaleSelect" title="Zoom" tabindex="23" data-l10n-id="zoom" @click="zoomSelect()">
                    <option id="pageAutoOption" title="" value="auto" data-l10n-id="page_scale_auto">Automatic Zoom</option>
                    <option id="pageActualOption" title="" value="page-actual" data-l10n-id="page_scale_actual">Actual Size</option>
                    <option id="pageFitOption" title="" value="page-fit" data-l10n-id="page_scale_fit">Page Fit</option>
                    <option id="pageWidthOption" title="" value="page-width" data-l10n-id="page_scale_width">Page Width</option>
                    <option title="" value="0.5" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 50 }'>50%</option>
                    <option title="" value="0.75" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 75 }'>75%</option>
                    <option title="" value="1" data-l10n-id="page_scale_percent" selected="selected" data-l10n-args='{ "scale": 100 }'>100%</option>
                    <option title="" value="1.25" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 125 }'>125%</option>
                    <option title="" value="1.5" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 150 }'>150%</option>
                    <option title="" value="2" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 200 }'>200%</option>
                    <option title="" value="3" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 300 }'>300%</option>
                    <option title="" value="4" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 400 }'>400%</option>
                  </select>
                </span>
              </div>
            </div>
            <div id="loadingBar">
              <div class="progress">
                <div class="glimmer">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="viewerContainer" class="viewer-container" ref="container">
          <div id="viewer" class="pdfViewer" ref="viewer"></div>
        </div>
      </div> <!-- mainContainer -->

      <div id="dialogContainer">
<!--#if !MOZCENTRAL-->
        <dialog id="printServiceDialog" style="min-width: 200px;">
          <div class="row">
            <span data-l10n-id="print_progress_message">Preparing document for printing…</span>
          </div>
          <div class="row">
            <progress value="0" max="100"></progress>
            <span data-l10n-id="print_progress_percent" data-l10n-args='{ "progress": 0 }' class="relative-progress">0%</span>
          </div>
          <div class="buttonRow">
            <button id="printCancel" class="dialogButton"><span data-l10n-id="print_progress_close">Cancel</span></button>
          </div>
        </dialog>
<!--#endif-->
<!--#if CHROME-->
<!--#include viewer-snippet-chrome-overlays.html-->
<!--#endif-->
      </div>  <!-- dialogContainer -->

    </div> <!-- outerContainer -->
    <div id="printContainer"></div>

<!--#if GENERIC-->
    <input type="file" id="fileInput" class="hidden">        
    </div>
</template>

<script>
import { getDocument } from 'pdfjs-dist';
import { PDFLinkService, PDFFindController, DefaultAnnotationLayerFactory, DefaultTextLayerFactory, PDFViewer, EventBus } from 'pdfjs-dist/web/pdf_viewer.js';
import 'pdfjs-dist/web/pdf_viewer.css'
import PdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

/*
TODO pdf rutscht manchmal nach links, wenn größere seiten z.b. kartne mitgeladen werden. ist normales pdf.js verhalten
*/
export default {
    name: 'PdfViewer',
    pdfViewer: null,
    pdfLoadingTask: null,
    props: {
        pdfID: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            page: 1,
            numPages: 0,
            scale: 1,
            opened: false,
        }
    },
    computed: {
        formattedZoom () {
            //TODO show in zoom menu?
            return Number.parseInt(this.scale * 100);
        },
    },
    mounted() {
        console.log(this.pdfID, this.$store.state.componentsStore.pdfPath, this.$store.state.componentsStore.pdfPath.length)
        if (this.pdfID >= this.$store.state.componentsStore.pdfPath.length) {
            return
        }
        this.source = this.$store.state.componentsStore.pdfPath[this.pdfID]
        this.textHighlight = this.$store.state.componentsStore.highlightText[this.pdfID]
        console.log(this.source)
        console.log(this.textHighlight)
        console.log(this.$store.state.componentsStore.pageNum[this.pdfID])

        //this.source = "http://127.0.0.1:3030/static/pdfs/f788f3c5cee627922820af2f6b2df12caa668afe5843405cb97a64cc2d4d7f4166842c8764a8d5820951021eaeb424612c91c72db813660846f59cb4a7fc04de.pdf"
        this.initViewer()
    },
    beforeDestroy() {
        this.pdfViewer.eventBus.off('pagechanging', this.handlePageChange)
        this.pdfViewer.eventBus.off('pagesinit', this.handleLoadPDF)
        this.pdfLoadingTask.destroy()
        this.pdfViewer = null
        this.pdfLoadingTask = null
    },
    methods: {
        initViewer() {
            // Event Communications
            const eventBus = new EventBus()
            // (Optionally) enable hyperlinks within PDF files
            const linkService = new PDFLinkService({ eventBus })
            // Create AnnotationLayer
            //const annotationLayer = new DefaultAnnotationLayerFactory();
            //Create TextLayer
            //const textLayer = new DefaultTextLayerFactory();
            // Enable find controller for search
            const findController = new PDFFindController({ eventBus, linkService })

            // Create PDFViewer
            this.pdfViewer = new PDFViewer({
                container: this.$refs.container,
                eventBus: eventBus,
                findController: findController,
                linkService: linkService,
                //annotationLayerFactory: annotationLayer,
                //textLayerFactory: textLayer
            })

            linkService.setViewer(this.pdfViewer)

            // Create EventListener
            this.pdfViewer.eventBus.on('pagechanging', this.handlePageChange)
            this.pdfViewer.eventBus.on('pagesinit', this.handleLoadPDF)

            // Create PDF LoadingTask
            this.pdfLoadingTask = getDocument({ url: this.source })
            this.pdfLoadingTask.promise.then((pdfDoc) => {
              // Set Document and Number of Pages after Load
              this.pdfViewer.setDocument(pdfDoc)
              linkService.setDocument(pdfDoc)
              this.numPages = pdfDoc.numPages
              this.$emit('on-loaded', pdfDoc.numPages)
            })
        },
        /*handleMatchResult(e) {
            let matchesCount = { ...e.matchesCount }
            const { _matchesCountTotal } = e.source
            if (_matchesCountTotal > 0 && matchesCount.total === 0) {
                matchesCount = { current: 1, total: _matchesCountTotal }
            }
            this.totalMatches = matchesCount.total
            if (this.totalMatches > 0) {
                this.currentMatch = 1
            }
            this.$emit('on-search', matchesCount)
        },*/
        handlePageChange(e) {
            this.page = e.pageNumber
            this.$emit('on-page-change', e.pageNumber)
            console.log("page-change")
        },
        handleLoadPDF() {
            this.page = this.$store.state.componentsStore.pageNum[this.pdfID]
            
            // jump to page with texthighlight (first occurence of gebot/verbot) after pages are loaded
            if (this.textHighlight) {
                this.searchQuery(this.textHighlight)
            }
            console.log("loaded")
        },
        searchQuery(query) {
            this.pdfViewer.eventBus.dispatch('find', {
                caseSensitive: false,
                phraseSearch: true,
                query: query,
                findPrevious: false,
                highlightAll: true
            })
        },        
        jumpToPage(page) {
            console.log("jump")
            this.pdfViewer.currentPageNumber = page
        },
        zoomSelect() {
            const select = document.getElementById("scaleSelect")
            let scale = select.options[select.selectedIndex].value
            console.log(scale, "zoom")
            this.pdfViewer.currentScaleValue = scale
        },
        zoom(scale) {
            console.log(scale, "zoom")
            this.pdfViewer.currentScaleValue = scale
        },
        download() {
            //TODO handle download
            console.log("download")
        },
        print() {
            //TODO handle print
            console.log("print")
            window.print();
        },
        fireSearch(type, findPrev=false) {
            this.pdfViewer.eventBus.dispatch("find", {
              type,
              query: document.getElementById("findInput").value,
              phraseSearch: true,
              caseSensitive: document.getElementById("findMatchCase").checked,
              entireWord: document.getElementById("findEntireWord").checked,
              highlightAll: document.getElementById("findHighlightAll").checked,
              findPrevious: findPrev,
              matchDiacritics: document.getElementById("findMatchDiacritics").checked
            });
        },
        searchKeyDown(e) {
            switch (e.keyCode) {
                case 13:
                    if (e.target === document.getElementById("findInput")) {
                        this.fireSearch("again", e.shiftKey);
                    }
                    break;
                case 27:
                    this.close();
                    break;
            }
        },
        findPrevious() {
            this.fireSearch("again", true)
        },
        findNext() {
            this.fireSearch("again", false)
        },
        highlightAllHandler() {
            this.fireSearch("highlightallchange")
        },
        caseSensitiveHandler() {
            this.fireSearch("casesensitivitychange")
        },
        entireWordHandler() {
            this.fireSearch("entirewordchange")
        },
        matchDiacriticsHandler() {
            this.fireSearch("diacriticmatchingchange")
        },
        open() {
            if (!this.opened) {
              this.opened = true;
              document.getElementById("viewFind").classList.add("toggled");
              document.getElementById("viewFind").setAttribute("aria-expanded", "true");
              document.getElementById("findbar").classList.remove("hidden");
            }
            document.getElementById("findInput").select();
            document.getElementById("findInput").focus();
            this.adjustWidth();
        },
        close() {
            if (!this.opened) {
              return;
            }
            this.opened = false;
            document.getElementById("viewFind").classList.remove("toggled");
            document.getElementById("viewFind").setAttribute("aria-expanded", "false");
            document.getElementById("findbar").classList.add("hidden");
            this.pdfViewer.eventBus.dispatch("findbarclose", {
              source: this
            });
        },
        toggle() {
            if (this.opened) {
              this.close();
            } else {
              this.open();
            }
        },
        adjustWidth() {
            console.log(!this.opened, "adjustWidth")
            if (!this.opened) {
              return;
            }
            document.getElementById("findbar").classList.remove("wrapContainers");
            const findbarHeight = document.getElementById("findbar").clientHeight;
            const inputContainerHeight = document.getElementById("findbar").firstElementChild.clientHeight;
            if (findbarHeight > inputContainerHeight) {
              document.getElementById("findbar").classList.add("wrapContainers");
            }
        },
    },
    watch: {
        scale: function(val) {
            this.zoom(val)
        },
        page: function(val) {
            //parse string
            if (typeof val === 'string' || val instanceof String) {
              val = Number(val.replace(/\D/g,''))  
            }
            //prevent 0
            if (val >= 1) {
                this.jumpToPage(val)
            }
        }
    }
}
</script>

<style>
@import '../plugins/viewer.css';

.viewer-container {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}

.page {
    border: none;
    margin: 16px auto;
}

.page:first-of-type {
    margin: 0 auto;
}

</style>